












import { Component, Vue } from 'vue-property-decorator';
import CForecastEdit from '@/modules/forecast/ForecastEdit.vue';
import Card from '../examples/components/Card.vue';

@Component({
    components: {
        'c-forecast-edit': CForecastEdit,
        Card
    }
})
export default class CForecastEditTest extends Vue {
    private edit = false;

    private editClk() {
        this.edit = true;
    }

    private saved(savedObj: any) {
        console.log('Сохранённый объект', savedObj);
    }

    private currentForecast: any = {
        'id': 651110,
        'indicator': {
            'id': 9,
            'parent': null,
            'code': 'pop',
            'name_ru': 'Численность населения (на начало периода)',
            'name_kz': 'Кезең басына халық саны',
            'name_en': 'Population size',
            'description': 'Количество людей, проживающих на данной территории в определенный момент времени',
            'character': 1,
            'createDate': 1609655022719,
            'updateDate': 1613995384371
        },
        'indicatorCustomName': 'test',
        'indicatorCustomNameKz': 'test',
        'indicatorCustomNameEn': 'test',
        'program': {
            'id': 214389,
            'parent': null,
            'code': 'GP_PRR_2020_2025',
            'name_ru': 'ГП развития регионов, 2020-2025',
            'name_kz': 'МБ Өңірлерді дамытудың мемлекеттік бағдарламасы, 2020-2025',
            'name_en': 'GP for the development of regions, 2020-2025',
            'description': 'Государственная программа развития регионов на 2020-2025 годы',
            'startDate': 1577836800000,
            'endDate': 1767139200000,
            'module': 'Модуль "Программы развития"',
            'statKato': null,
            'createDate': 1618291138013,
            'updateDate': 1619544479212
        },
        'direction': {
            'id': 214669,
            'parent': null,
            'code': 'obpp',
            'name_ru': 'Общественная безопасность и правопорядок',
            'name_kz': 'Общественная безопасность и правопорядок',
            'name_en': 'Общественная безопасность и правопорядок',
            'description': null,
            'createDate': 1618458304584,
            'updateDate': 1618458304584
        },
        'sphere': {
            'id': 146,
            'parent': null,
            'code': 'ntjs',
            'name_ru': 'Наращивание темпов жилищного строительства для обеспечения доступности жилья для граждан',
            'name_kz': null,
            'name_en': null,
            'description': null,
            'createDate': 1612420162127,
            'updateDate': 1612420162127
        },
        'goals': {
            'id': 7,
            'parent': null,
            'code': '05',
            'name_ru': 'Улучшение инвестиционного климата в регионе',
            'name_kz': 'Улучшение инвестиционного климата в регионе',
            'name_en': 'Улучшение инвестиционного климата в регионе',
            'description': 'цель 5 ПРТ 2016-2020',
            'createDate': 1620300690154,
            'updateDate': 1620300690154
        },
        'statKato': {
            'id': 20,
            'parent': 17,
            'code': '111843107',
            'name_ru': 'с.Первомайка',
            'name_kz': 'Первомайка а.',
            'date_end': null
        },
        'statUnit': {
            'id': 55,
            'parent': 5,
            'code': '003.113',
            'name_ru': 'Метр кубический',
            'name_kz': 'текше м',
            'nationalSymbol': 'м3',
            'date_end': null
        },
        'years': [
            {
                'id': 650943,
                'year': 2021,
                'plan': 45,
                'factOld': null,
                'fact': null,
                'risk': null,
                'startMonth': null,
                'endMonth': null,
                'dvalue': null
            }
        ],
        'abps': [
            {
                'id': 15,
                'gr': 1,
                'pgr': 5,
                'abp': 258,
                'prg': 5,
                'ppr': null,
                'dictEbkFuncDto': null
            },
            {
                'id': 16,
                'gr': 1,
                'pgr': 5,
                'abp': 258,
                'prg': 1,
                'ppr': null,
                'dictEbkFuncDto': null
            }
        ],
        'statSetting': null,
        'coefficient': 1,
        'arithmetic_operation': null,
        'executor': null,
        'smData': null
    };
}

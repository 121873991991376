<template>
    <div class="text-danger">
        removed!
    </div>
</template>

<script>
    export default {
        name: 'card'
    }
</script>